.f8-400 {
  font-weight: 400;
   font-family: $fontFamily;
  font-size: 8px;
}

.f8-500 {
  font-weight: 500;
  font-size: 8px;
  font-family: $fontFamily;
}

.f8-700 {
  font-weight: 700;
  font-size: 8px;
  font-family: $fontFamily;
}

.f9-400 {
  font-weight: 400;
   font-family: $fontFamily;
  font-size: 9px;
}

.f9-500 {
  font-weight: 500;
  font-size: 9px;
  font-family: $fontFamily;
}

.f9-700 {
  font-weight: 700;
  font-size: 9px;
  font-family: $fontFamily;
}

.f10-400 {
  font-weight: 400;
   font-family: $fontFamily;
  font-size: 10px;
}

.f10-500 {
  font-weight: 500;
  font-size: 10px;
  font-family: $fontFamily;
}

.f10-700 {
  font-weight: 700;
  font-size: 10px;
  font-family: $fontFamily;
}

.f11-400 {
  font-weight: 400;
   font-family: $fontFamily;
  font-size: 11px;
}

.f11-500 {
  font-weight: 500;
  font-size: 11px;
  font-family: $fontFamily;
}

.f11-700 {
  font-weight: 700;
  font-size: 11px;
  font-family: $fontFamily;
}

.f12-400 {
  font-weight: 400;
   font-family: $fontFamily;
  font-size: 12px;
}

.f12-500 {
  font-weight: 500;
  font-size: 12px;
  font-family: $fontFamily;
}

.f12-700 {
  font-weight: 700;
  font-size: 12px;
  font-family: $fontFamily;
}

.f13-400 {
  font-weight: 400;
   font-family: $fontFamily;
  font-size: 13px;
}

.f13-500 {
  font-weight: 500;
  font-size: 13px;
  font-family: $fontFamily;
}

.f13-700 {
  font-weight: 700;
  font-size: 13px;
  font-family: $fontFamily;
}

.f14-400 {
  font-weight: 400;
   font-family: $fontFamily;
  font-size: 14px;
 
}

.f14-500 {
  font-weight: 500;
  font-size: 14px;
  font-family: $fontFamily;
}

.f14-700 {
  font-weight: 700;
  font-size: 14px;
  font-family: $fontFamily;
}

.f15-500 {
  font-weight: 500;
  font-size: 15px;
  font-family: $fontFamily;
}

.f15-700 {
  font-weight: 700;
  font-size: 15px;
  font-family: $fontFamily;
}

.f16-500 {
  font-weight: 500;
  font-size: 16px;
  font-family: $fontFamily;
}

.f16-400 {
  font-weight: 400;
   font-family: $fontFamily;
  font-size: 16px;
}

.f16-700 {
  font-weight: 700;
  font-size: 16px;
  font-family: $fontFamily;
}

.f18-400 {
  font-weight: 400;
   font-family: $fontFamily;
  font-size: 18px !important;
}

.f18-500 {
  font-weight: 500;
  font-size: 18px !important;
  font-family: $fontFamily;
}

.f18-700 {
  font-weight: 700;
  font-size: 18px !important;
  font-family: $fontFamily;
}

.f19-500 {
  font-weight: 500;
  font-size: 19px !important;
  font-family: $fontFamily;
}

.f20-400 {
  font-weight: 400;
   font-family: $fontFamily;
  font-size: 20px !important;
}

.f20-500 {
  font-weight: 500;
  font-size: 20px !important;
  font-family: $fontFamily;

}

.f20-700 {
  font-weight: 700;
  font-size: 20px !important;
  font-family: $fontFamily;
}

.f21-700 {
  font-weight: 700;
  font-size: 21px;
  font-family: $fontFamily;
}

.f24-500 {
  font-weight: 500;
  font-size: 24px;
  font-family: $fontFamily;
}

.f24-700 {
  font-weight: 700;
  font-size: 24px;
  font-family: $fontFamily;
}

.f28-500 {
  font-weight: 500;
  font-size: 28px;
  font-family: $fontFamily;
}

.f30-700 {
  font-weight: 700;
  font-size: 30px;
  font-family: $fontFamily;
}

.f36-500 {
  font-weight: 500;
  font-size: 36px;
  font-family: $fontFamily;
}
