.add-tip {
  border: 1px solid rgba(128, 128, 128, 0.477);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  margin: 12px 0;
}
.add-tip-dropdown {
  background-color: #c8c8f826;
}

.shadow-button {
  background-color: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border: 1px solid #ffffff;
  border-radius: 11px;
}
