@import './assets/scss/global.scss';
@import './assets/scss/experience.scss';
@import './assets/scss/seating.scss';
@import './assets/scss/fonts.scss';
@import './assets/scss/restaurantdetails.scss';
@import './assets/scss/colors.scss';
@import './assets/scss/ordering.scss';
@import './assets/scss/PersonlizationCustomization.scss';
@import '~bootstrap/scss/bootstrap';
@import '~video-react/styles/scss/video-react';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  //  height: 100%;
}

body {
  font-family: $fontFamily;
  width: 100% !important;
  min-height: 100vh !important;
}
