.personalisationcustomisation {
  .cnpcontainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    width: 100%;
    min-width: 400px;
    /* box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);  */
  }

  .icon-button {
    width: 10%;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
  }

  .title-container {
    width: 90%;
  }

  .title-row,
  .rating-row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .veg-icon,
  .rating-star {
    margin-right: 5px;
    width: 13px;
    height: 13px;
  }
  .left-icon {
    width: 28px;
    height: 28px;
  }

  .rating-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 10px;
  }

  .rating-star {
    width: 8px;
    height: 8px;
    margin-right: 3px;
  }
  .rating-row {
    align-items: center;
  }

  .tabs-container {
    display: flex;
    padding: 0;
    margin: 0;
  }

  .tab-button {
    padding: 10px 20px;
    border: none;
    border-bottom: 3px solid transparent;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    font-size: 16px;
  }

  /* .tab-button:hover {
    background-color: #f0f0f0;
  } */

  .tab-button.active {
    border-bottom-color: #212121;
    color: #212121;
  }

  .tab-content {
    padding: 20px;
    border: 1px solid #ccc;
    border-top: none;
  }
  .level_bloc {
    border-width: 1px;
  }
  .sliderContainer {
    width: 100%; /* or a specific wider width */
    padding: 0 20px; /* Add some padding if necessary */
  }
  .scrollable-div {
    height: '80%';
    background-color: white;
    overflow-y: auto; /* Enable vertical scrollbar if content exceeds height */
  }
  .levelContainer {
    border-width: 1px;
    border-radius: 14px;
    padding: 10px;
    display: flex;
    width: fit-content;
    height: auto;
    border: 1px solid grey;
  }
  .fullScreenModal .modal-dialog {
    max-width: 100vw;
    min-height: 100vh;
    margin: 0;
  }

  .fullScreenModal .modal-content {
    height: 100%;
    border-radius: 0;
  }
  .cardShadow {
    margin: 10px;
    border-radius: 20px;
    background-color: transparent;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.22);
  }

  .cardContainer {
    background-color: #fff;
    border-radius: 20px;
    overflow: hidden;
  }

  .textInput {
    width: 100%;
    height: 150px;
    padding: 10px;
    resize: vertical;
    border: none;
    border-radius: 20px;
    box-sizing: border-box;
  }
  /* CheckBoxWithText.css */
  .checkboxContainer {
    display: flex;
    align-items: center;
  }

  .checkbox {
    width: 16px;
    height: 16px;
    border: 1px solid grey;
    border-radius: 4px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .radiobox {
    width: 16px;
    height: 16px;
    border: 1px solid grey;
    border-radius: 8px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .checkbox.checked {
    background-color: #ee3a23;
    color: #fff;
  }

  .labelText {
    font-size: 16px;
  }
}
